.container {
  padding: 0;
  margin: 0;
  display: flex;
  width: 100vw;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  flex-direction: column;
  justify-content: center;
}

/* CONTAINERS AND STUFF */

.hodler {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top {
  display: flex;
  flex-direction: column;
  background: rgba(24, 26, 27, 0.8);
}

.bot {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
}

.left {
  display: flex;
  flex-direction: column;
  grid-area: left;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  background: rgba(56, 60, 62, 0.7);
}

.max-and-center {
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.right {
  display: flex;
  flex-direction: column;
  grid-area: right;
  overflow: hidden;
  flex: 1;
  background: rgba(56, 60, 62, 0.7);
}

/* NICE BUTTON */

.style-button:hover {
  background-color: rgba(67, 73, 76, 0.57);
  cursor: pointer;
}

.style-button {
  background-color: rgba(24, 26, 27, 0.77);
  height: 40px;
  display: flex;
  border-radius: 1em;
  padding: 0;
  margin: 0;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.style-button:disabled {
  color: #808e9b;
  background-color: rgba(24, 26, 27, 0.77);
}

/* AUTO GRID */

.grid {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.grid-grid {
  display: grid;
  z-index: 1;
  border-radius: 1em;
  padding: 0.2em;
  background-color: rgb(27, 30, 31);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  grid-gap: 2px;
  grid-template-columns: repeat(2, 80px);
  grid-template-rows: auto;
}

.grid-button {
  background-color: rgba(67, 73, 76, 0.57);
  border-radius: 1em;
  padding: 0.2em;
  overflow: none;
  flex-wrap: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  align-content: center;
}

.grid-button:hover {
  background-color: rgba(187, 187, 187, 0.568);
}

/* NON-MOBILE PROPERTIES */

/* For when there is more screen real estate */
@media (min-width: 640px) {
  .grid-grid {
    padding: 0.4em;
    grid-template-columns: repeat(3, 120px);
    grid-template-rows: auto;
  }
  .grid-button {
    padding: 0.4em;
  }
}
@media (min-width: 1024px) {
  .left {
    display: flex;
    flex-direction: column;
    grid-area: left;
    grid-area: left;
    overflow: auto;
    grid-area: left;
    overflow: auto;
    min-width: 0;
    min-width: 500px;
    max-width: 500px;
    background: rgba(56, 60, 62, 0.7);
  }
  .right {
    display: flex;
    flex-direction: column;
    grid-area: right;
    overflow: hidden;
    overflow-y: auto;
    min-width: 0;
    min-width: 400px;
    max-width: 400px;
    background: rgba(56, 60, 62, 0.7);
  }

  .grid-grid {
    padding: 0.6em;
    grid-template-columns: repeat(3, 140px);
    grid-template-rows: auto;
  }
  .grid-button {
    padding: 0.6em;
  }
}

/* TOOLTIP  */
.tooltip {
  text-decoration: none;
  text-align: center;
  position: relative;
}
.tooltip p {
  position: relative;
  display: inline-block;
  align-self: center;
  border-bottom: 1px dashed white;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  bottom: 100%;
  left: 50%;
  margin-left: -90px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 3;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
